export const getSiblings = el => {
    var siblings = [];
    var sibling = el.parentNode.firstChild;
    for( ; sibling; sibling = sibling.nextSibling) {
        if(sibling.nodeType === 1 && sibling !== el) {
            siblings.push(sibling)
        }
    }
    return siblings
}

export const resizeGame = () => {
    const gameArea = document.getElementById('app');
    const widthToHeight = 16 / 9;
    let newWidth = document.documentElement.clientWidth;
    let newHeight = document.documentElement.clientHeight;
    const newWidthToHeight = newWidth / newHeight;

    if (newWidthToHeight > widthToHeight) {
      newWidth = newHeight * widthToHeight;
      gameArea.style.height = `${newHeight}px`;
      gameArea.style.width = `${newWidth}px`;
    } else {
      newHeight = newWidth / widthToHeight;
      gameArea.style.width = `${newWidth}px`;
      gameArea.style.height = `${newHeight}px`;
    }

    gameArea.style.marginTop = `-${newHeight / 2}px`;
    gameArea.style.marginLeft = `-${newWidth / 2}px`;
}